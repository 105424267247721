import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { UtilsService } from "src/app/services/utils/utils.service";
import projects from "src/assets/projects";
import { PATHS, ROUTES } from "src/constants";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  public ROUTES = ROUTES;
  public PATHS = PATHS;
  public headerClass: "hidden" | "showed";
  constructor(private utils: UtilsService, @Inject("isBrowser") private isBrowser: boolean) {}

  ngOnInit() {
    this.headerClass = "showed";
    if (this.isBrowser) window.addEventListener("scroll", this.onWindowScroll.bind(this), true);
  }

  private onWindowScroll(): void {
    let newHeaderClass = this.headerClass;
    if (window.scrollY < 50) {
      newHeaderClass = "showed";
    } else {
      newHeaderClass = "hidden";
    }
    if (newHeaderClass !== this.headerClass) this.headerClass = newHeaderClass;
  }

  public preloadWorksImages(): void {
    this.utils.preloadImage(projects.map(p => p.thumbnail.image));
  }
}
