<div class="incubator">
  <div class="incubator__a">
    <div class="incubator__a__wrapper">
      <div class="incubator__a__content">
        <h2 class="incubator__title --big">We believe in your vision</h2>
        <p class="incubator__text">
          coara is a startup building agency. We get frequently contacted by founders who have a great team and a great idea, but they still
          lack some ingredient to be able to build a product and thrive. This is where coara comes into play as an agency. Some of these
          founders and ideas are so outstanding though, that we want to have them in our coara incubator.
        </p>
        <a
          class="incubator__button"
          href="https://form.typeform.com/to/d4t8FTzP?typeform-medium=embed-snippet"
          data-mode="popup"
          target="_blank"
          >Apply for incubator</a
        >
      </div>
      <img loading="lazy" src="/assets/images/incubator/001.png" alt="incubator" />
    </div>
  </div>

  <div class="incubator__b">
    <div class="incubator__b__wrapper">
      <h3 class="incubator__title --green">Why coara incubator</h3>
      <div class="incubator__b__content">
        <div class="incubator__b__content__item">
          <div class="incubator__b__content__item__icon">
            <img loading="lazy" src="/assets/images/incubator/passion-icon.svg" alt="incubator" />
          </div>
          <h4 class="incubator__b__content__item__title">Passion</h4>
          <p class="incubator__b__content__item__text">
            Building companies is our passion. We want you to succeed. Your growth story is our motivation.
          </p>
        </div>
        <div class="incubator__b__content__item">
          <div class="incubator__b__content__item__icon">
            <img loading="lazy" src="/assets/images/incubator/fast-icon.svg" alt="incubator" />
          </div>
          <h4 class="incubator__b__content__item__title">Fast</h4>
          <p class="incubator__b__content__item__text">
            We can complete your team with the skills that you are lacking or where you just don’t have enough resources to act quickly and
            build and launch fast.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="incubator__c">
    <div class="incubator__c__wrapper">
      <h3 class="incubator__title">How we work</h3>
      <div class="incubator__c__content">
        <div class="incubator__c__content__item">
          <p class="incubator__c__content__item__text">When we believe your business model works we go on a 3 months journey together</p>
        </div>
        <div class="incubator__c__content__item">
          <p class="incubator__c__content__item__text">We invest anywhere between 20-50k in form of a convertible loan into your company</p>
        </div>
      </div>
      <div class="incubator__c__banner">
        <p class="incubator__c__banner__text">
          The loan is used to create an MVP as well as marketing and sales efforts to test and validate your idea. The coara startup builder
          complements your existing team. So if you have a great idea but no limited developmemt and UX capacity we talk to your users
          together and create your UX and UI and build your first product. To get traction we help you with marketing an sales.
        </p>
      </div>
    </div>
  </div>

  <div class="incubator__d">
    <div class="incubator__d__wrapper">
      <h3 class="incubator__title --green">What we do</h3>
      <div class="incubator__d__item --left">
        <ul class="incubator__d__item__list">
          <li class="incubator__d__item__list__item">
            - Our marketing and sales teams and our network will help you to get traction even before you build your MVP. We only invest in
            products that sell.
          </li>
          <li class="incubator__d__item__list__item">
            - We can create your <a href="https://coara.co/blog/market-potential-analysis" class="text">market potential analysis</a>.
          </li>
          <li class="incubator__d__item__list__item">
            - Once we know that you solve a real problem, we focus 100% on your user’s experience. Whatever you need from our team to get it
            right is at your disposal.
          </li>
        </ul>
        <img loading="lazy" src="/assets/images/incubator/004.png" alt="incubator" />
      </div>
      <div class="incubator__d__item --right">
        <img loading="lazy" src="/assets/images/incubator/005.png" alt="incubator" />
        <ul class="incubator__d__item__list">
          <li class="incubator__d__item__list__item">
            - Our developers are fast and efficient and work with modern frameworks. If you want to outsource all or parts of your
            development we are there for you.
          </li>
          <li class="incubator__d__item__list__item">
            - Within 3 months you have a running MVP which you are testing in the market to get feedback on the product and on actual sales
            numbers.
          </li>
          <li class="incubator__d__item__list__item">
            - We can build and execute your SEO / content strategy and blog, or advise you on it.
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="incubator__b --top-space">
    <div class="incubator__b__wrapper">
      <h3 class="incubator__title --green">Other Benefits</h3>
      <div class="incubator__b__content">
        <div class="incubator__b__content__item">
          <div class="incubator__b__content__item__icon --no-title">
            <img loading="lazy" src="/assets/images/incubator/world-icon.svg" alt="incubator" />
          </div>
          <p class="incubator__b__content__item__text">
            We are truly remote and local at the same time. We have been digital nomads. And we have found the best place for us to build a
            company in Europe: Estonia - The first country with an e-residency.
          </p>
        </div>
        <div class="incubator__b__content__item">
          <div class="incubator__b__content__item__icon --no-title">
            <img loading="lazy" src="/assets/images/incubator/hands-icon.svg" alt="incubator" />
          </div>
          <p class="incubator__b__content__item__text">
            If you decide to follow our footsteps we support you with the company setup and legal / accounting stuff. Also with getting a
            bank account from a digital provider. Of course this is optional, you should set up your company wherever it best fits your
            business.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="incubator__e">
    <div class="incubator__e__wrapper">
      <h3 class="incubator__title">How we choose founders to become part of the coara incubator</h3>
      <div class="incubator__e__banner">
        <p class="incubator__e__banner__text">
          We focus on second time founders and complement their team / needs with our knowledge. We also work with first time founders if
          they have a real problem which they want to solve for themselves and where it is credible that not only themselves but many others
          have this problem. It is of great benefit if you can prove interest from potential customers. Our core expertise is around UX and
          prodct / software development, so your team should be short of ressources in that area to be a good fit. We are based mostely in
          Europe, so chances are that we can help you better with market access if you are here. But we are also digital nomads and happy to
          help people who live the same philosophy.
        </p>
      </div>
    </div>
  </div>

  <div class="incubator__f">
    <div class="incubator__f__wrapper">
      <h3 class="incubator__title --green">Perks</h3>
      <div class="incubator__f__grid">
        <div class="incubator__f__grid__item">
          <img src="/assets/images/incubator/logos/scaleway.png" alt="" class="incubator__f__grid__item__logo" />
          <p class="incubator__f__grid__item__text">Scaleway Cloud Credit (European AWS Challenger)</p>
        </div>
        <div class="incubator__f__grid__item">
          <img src="/assets/images/incubator/logos/sendinblue.png" alt="" class="incubator__f__grid__item__logo" />
          <p class="incubator__f__grid__item__text">Send In Blue Credit (European Sendgrid Challenger)</p>
        </div>
        <div class="incubator__f__grid__item">
          <img src="/assets/images/incubator/logos/pipedrive.png" alt="" class="incubator__f__grid__item__logo" />
          <p class="incubator__f__grid__item__text">
            Pipedrive Credit (Focus on selling, not on using a tool - Brought to you from Estonia)
          </p>
        </div>
        <div class="incubator__f__grid__item">
          <img src="/assets/images/incubator/logos/crisp.png" alt="" class="incubator__f__grid__item__logo" />
          <p class="incubator__f__grid__item__text">
            Crisp - The perfect chat for lead generation and customer service - Made with love in France
          </p>
        </div>
        <div class="incubator__f__grid__item">
          <img src="/assets/images/incubator/logos/typeform.png" alt="" class="incubator__f__grid__item__logo" />
          <p class="incubator__f__grid__item__text">Typeform - Leads, leads, leads, right into pipedrive (Made in Barcelona)</p>
        </div>
        <div class="incubator__f__grid__item">
          <a href="https://www.integromat.com/?pc=coara" target="_balnk">
            <img src="/assets/images/incubator/logos/integromat.png" alt="" class="incubator__f__grid__item__logo" />
            <p class="incubator__f__grid__item__text">Integromat - The better Zapier - from Czech Republic</p>
          </a>
        </div>
        <div class="incubator__f__grid__item">
          <img src="/assets/images/incubator/logos/figma.png" alt="" class="incubator__f__grid__item__logo" />
          <p class="incubator__f__grid__item__text">Figma the Design & UX Tool</p>
        </div>
        <div class="incubator__f__grid__item">
          <img src="/assets/images/incubator/logos/curolla.png" alt="" class="incubator__f__grid__item__logo" />
          <p class="incubator__f__grid__item__text">Hotel Curolla on an Med Island for your launch workshop</p>
        </div>
        <div class="incubator__f__grid__item">
          <img src="/assets/images/incubator/logos/seedglegals.png" alt="" class="incubator__f__grid__item__logo" />
          <p class="incubator__f__grid__item__text">Seed Legals to get your legal stuff right and to be prepared for investment</p>
        </div>
      </div>
    </div>
  </div>

  <div class="incubator__g">
    <div class="incubator__g__wrapper">
      <h3 class="incubator__title">Our Partners</h3>
      <p class="incubator__text --big">
        Depending on what industry you are in we actively encourage you to join an industry specific accelerator, to get you the relevant
        network.
        <br />
        <strong>Some partners we work with:</strong>
      </p>
      <div class="incubator__g__banner">
        <ul class="incubator__g__banner__list">
          <li class="incubator__g__banner__list__item">
            - <a href="https://www.yachtingventures.co/" target="_blank" class="text">Yachting Ventures</a> - For Boat Industry Related
            Startups
          </li>
          <li class="incubator__g__banner__list__item">
            - <a href="https://decelera.com/" target="_blank" class="text">Decelera</a> - For Sustainable Startups
          </li>
          <li class="incubator__g__banner__list__item">- Mallorca Startups - Local Entrepreneur Group in the Med</li>
          <li class="incubator__g__banner__list__item">- Sunshine Embassy - your co-living space</li>
        </ul>
      </div>
    </div>
  </div>

  <div class="incubator__h">
    <div class="incubator__h__wrapper">
      <h3 class="incubator__title --small">Apply for coara incubator</h3>
      <p class="incubator__text --dark">
        We'll ask you ten questions, including your company presentation. We get back to you generally within one week if we see a product
        fit for the incubator.
      </p>
      <a
        class="incubator__button --dark"
        href="https://form.typeform.com/to/d4t8FTzP?typeform-medium=embed-snippet"
        data-mode="popup"
        target="_blank"
        >Complete the form</a
      >
    </div>
  </div>
</div>
