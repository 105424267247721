<footer class="footer">
  <div class="footer__wrapper">
    <p class="footer__title">
      Want to know us better?
      <br />
      Let's talk
    </p>
    <div class="footer__content">
      <ul class="footer__list">
        <li class="footer__list__item"><a href="mailto:contact@coara.co" class="footer__list__link --underline"> contact@coara.co</a></li>
        <li class="footer__list__item"><span class="footer__list__link">+49(89)24412581</span></li>
        <li class="footer__list__item"><span class="footer__list__link">We work in Germany · Switzerland · Estonia and Spain</span></li>
      </ul>
      <div class="footer__content__wrapper">
        <ul class="footer__list">
          <li class="footer__list__item">
            <a [routerLink]="PATHS[ROUTES.BLOG]" class="footer__list__link --underline" title="blog link">
              Blog
            </a>
          </li>
          <li class="footer__list__item">
            <a [routerLink]="PATHS[ROUTES.INCUBATOR]" class="footer__list__link --underline" title="incubator link">
              Incubator
            </a>
          </li>
          <li class="footer__list__item">
            <a
              href="https://www.facebook.com/coaracoders/"
              class="footer__list__link --underline"
              target="_blank"
              rel="noopener"
              title="facebook link"
            >
              facebook
            </a>
          </li>
          <li class="footer__list__item">
            <a
              href="https://www.linkedin.com/company/coara"
              class="footer__list__link --underline"
              target="_blank"
              rel="noopener"
              title="linkedin link"
            >
              Linkedin
            </a>
          </li>
        </ul>
        <div class="footer__startups">
          <h2 class="footer__startups__title">Our startups</h2>
          <ul class="footer__startups__list">
            <li class="footer__startups__list__item">
              <a href="https://www.jericommerce.com/" target="_blank">Jericommerce - Wallet Passes</a>
            </li>
            <li class="footer__startups__list__item">
              <a href="https://planm8.io/" target="_blank">Plan M8 - Yacht Maintenance Software</a>
            </li>
            <li class="footer__startups__list__item">
              <a href="https://nomadvillagebrazil.com/" target="_blank">Nomad Village Brazil</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</footer>
