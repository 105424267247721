import { Component, Inject, NgZone, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import projects, { IProject } from "src/assets/projects";
import { SeoService } from "src/app/services/seo/seo.service";

@Component({
  selector: "app-project",
  templateUrl: "./project.component.html",
  styleUrls: ["./project.component.scss"],
})
export class ProjectComponent implements OnInit {
  public project: IProject;
  public nextProject: IProject;
  public prevProject: IProject;
  public stickyProjectNav: boolean;
  constructor(
    private activatedRoute: ActivatedRoute,
    private ngZone: NgZone,
    @Inject("isBrowser") private isBrowser: boolean,
    private seo: SeoService,
  ) {}

  ngOnInit() {
    this.stickyProjectNav = false;
    if (this.isBrowser) window.addEventListener("scroll", this.onWindowScroll.bind(this), true);
    this.activatedRoute.url.subscribe(segments => {
      const index = projects.findIndex(project => !!project.uri.match(segments[0].path));
      this.project = projects[index];
      if (index === projects.length - 1) {
        this.nextProject = projects[0];
        this.prevProject = projects[index - 1];
      } else if (index === 0) {
        this.nextProject = projects[index + 1];
        this.prevProject = projects[projects.length - 1];
      } else {
        this.nextProject = projects[index + 1];
        this.prevProject = projects[index - 1];
      }
      this.seo.setCanonicalURL();
      this.seo.updateMeta({
        title: `${this.project.title} - ${this.project.subtitle}`,
        description: this.project.shortDescription,
        imageUrl: `${this.project.images[0].image}.jpg`,
      });
    });
  }

  private onWindowScroll(): void {
    const newState = window.scrollY > 50;
    if (newState !== this.stickyProjectNav) this.ngZone.run(() => (this.stickyProjectNav = newState));
  }
}
