<header class="header --{{ headerClass }}">
  <div class="wrapper">
    <a [routerLink]="PATHS[ROUTES.HOME]">
      <app-logo></app-logo>
    </a>
    <ul class="header__nav">
      <li class="header__nav__item">
        <a [routerLink]="PATHS[ROUTES.WHAT]" title="What" class="header__nav__link">What</a>
      </li>
      <li class="header__nav__item">
        <a [routerLink]="PATHS[ROUTES.WORKS]" title="Works" (mouseenter)="preloadWorksImages()" class="header__nav__link">Works</a>
      </li>
    </ul>
  </div>
</header>
