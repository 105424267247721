<div class="four-hundred-four">
  <h1 class="four-hundred-four__number">
    <span>4</span>
    <span>0</span>
    <span>4</span>
  </h1>
  <h4 class="four-hundred-four__text">
    Ouch...
  </h4>
</div>
