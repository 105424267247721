<!-- INTRO SECTION -->
<section class="section intro">
  <div class="wrapper">
    <div class="pager">
      <div class="pager__line"></div>
      <ul class="pager__dots">
        <li class="pager__dot"></li>
        <li class="pager__dot"></li>
        <li class="pager__dot"></li>
      </ul>
    </div>
    <h1 class="intro__title">We believe in<br />your vision</h1>
    <h2 class="intro__text">
      We are truly passionate about innovation and disruption and we want to make your ideas become real.
    </h2>
    <app-button [href]="'mailto:contact@coara.co'" text="Get in touch" class="intro__button"></app-button>
    <ng-lottie
      containerClass="intro__animation"
      [options]="mouseAnimation"
      width="65px"
      height="169px"
      (animationCreated)="animationCreated($event)"
    ></ng-lottie>
  </div>
</section>

<!-- MAIN BANNER -->
<section class="section main-banner">
  <div class="main-banner__wrapper">
    <h3 class="main-banner__title">The startup agency</h3>
    <p class="main-banner__text">
      We empower founders to innovate and develop faster. We are an incubator for your idea, your developers, an accelerator to your
      business and a growth hack for gaining customers.
    </p>
    <div class="main-banner__footer">
      <app-button [routerLinkDelagate]="PATHS[ROUTES.WHAT]" blackOnMobile="true" text="What we do" class="main-banner__button"></app-button>
      <div class="main-banner__footer__text">Design and Development of Digital Products</div>
    </div>
  </div>
</section>

<!-- BUILDING SECTION -->
<section class="section building --with-carousel">
  <h2 class="section__title">Building products together</h2>
  <app-carousel>
    <div class="building__item">
      <picture class="building__item__img">
        <source *ngFor="let type of ['webp', 'jpg']" srcset="{{'/assets/images/building/001.' + type}}" type="image/{{ type }}" />
        <img loading="lazy" src="/assets/images/building/001.jpg" alt="ideation" />
      </picture>
      <p class="building__item__title">01. Ideation</p>
      <p class="building__item__text">
        Define and design your product and create a perfect UX. Explore consumer need and market strategy
      </p>
    </div>
    <div class="building__item">
      <picture class="building__item__img">
        <source *ngFor="let type of ['webp', 'jpg']" srcset="{{'/assets/images/building/002.' + type}}" type="image/{{ type }}" />
        <img loading="lazy" src="/assets/images/building/002.jpg" alt="Bulding" />
      </picture>
      <p class="building__item__title">02. Building</p>
      <p class="building__item__text">Create true MVPs and iterate towards the perfect product with the help of your early adopters</p>
    </div>
    <div class="building__item">
      <picture class="building__item__img">
        <source *ngFor="let type of ['webp', 'jpg']" srcset="{{'/assets/images/building/003.' + type}}" type="image/{{ type }}" />
        <img loading="lazy" src="/assets/images/building/003.jpg" alt="Growth" />
      </picture>
      <p class="building__item__title">03. Growth</p>
      <p class="building__item__text">
        Make the right hacks to get your first customers and define your growth engine for sustainability
      </p>
    </div>
  </app-carousel>
</section>

<!-- LEAN BANNER -->
<div class="lean-banner">
  <h3 class="lean-banner__title">Your lean startup</h3>
  <div class="lean-banner__wrapper">
    <div class="lean-banner__content">
      <div class="lean-banner__item">
        <div class="lean-banner__item__image">
          <img loading="lazy" src="/assets/images/lean-banner/002.png" alt="Lean icon" />
        </div>
        <p class="lean-banner__item__text">Set and define objective</p>
      </div>
      <div class="lean-banner__item">
        <div class="lean-banner__item__image">
          <img loading="lazy" src="/assets/images/lean-banner/004.png" alt="Car icon" />
        </div>
        <p class="lean-banner__item__text">Start your journey. Early!</p>
      </div>
      <div class="lean-banner__item">
        <div class="lean-banner__item__image">
          <img loading="lazy" src="/assets/images/lean-banner/001.png" alt="Atom icon" />
        </div>
        <p class="lean-banner__item__text">Measure and learn fast</p>
      </div>
      <div class="lean-banner__item">
        <div class="lean-banner__item__image">
          <img loading="lazy" src="/assets/images/lean-banner/003.png" alt="Iteration logo" />
        </div>
        <p class="lean-banner__item__text">Iterate and improve</p>
      </div>
    </div>
  </div>
</div>

<!-- SENTENCE -->
<section class="section sentence">
  <div class="sentence__wrapper">
    <p class="sentence__text">
      If you're afraid of failure or getting it wrong. you'll never truly innovate or disrupt the market.
      <br />
      <span class="signature">Gillian Tans, CEO booking.com</span>
    </p>
    <ng-lottie [options]="infiniteCirclesAnimation" width="100%" height="auto" (animationCreated)="animationCreated($event)"></ng-lottie>
  </div>
</section>

<!-- LABYRINTH -->
<section class="section labyrinth">
  <div class="labyrinth__content">
    <h2 class="section__title">You are a corporate team and want to escape the labyrinth</h2>
    <p class="section__text">You want to drive an in-house innovation project in your company?</p>
    <p class="section__list-title">But</p>
    <ul class="section__list">
      <li class="section__list__item">Your imagination is bounded by corporate processes</li>
      <li class="section__list__item">You want to move fast and IT restrictions and resources hold you back?</li>
      <li class="section__list__item">Brand constrains dont't allow disruption of your core business?</li>
    </ul>
  </div>
  <ng-lottie [options]="labyrinthAnimation" width="100%" height="auto" (animationCreated)="animationCreated($event)"></ng-lottie>
</section>

<!-- OUR WORK -->
<section class="section work --with-carousel">
  <h2 class="section__title">
    <h3 class="section__title__wrapper">
      Let's break out and
      <br />
      Truly innovate!
      <br />
      <app-button [routerLinkDelagate]="PATHS[ROUTES.WORKS]" text="Our work"></app-button>
    </h3>
  </h2>
  <app-carousel>
    <a class="work__item" [routerLink]="[PATHS[ROUTES.WORKS], project.uri]" *ngFor="let project of projectsToShow; let i = index">
      <picture class="work__item__image">
        <source *ngFor="let type of ['webp', 'jpg']" srcset="{{project?.thumbnail?.image + '.' + type}}" type="image/{{ type }}" />
        <img [attr.loading]="i <= 3 ? 'eager' : 'lazy'" src="{{ project?.thumbnail?.image }}.jpg" alt="{{ project?.thumbnail?.alt }}" />
      </picture>
    </a>
  </app-carousel>
</section>

<!-- SECTION CONTACT -->
<section class="section contact">
  <div *ngIf="!leadSent" class="contact__wrapper">
    <h3 class="section__title">Sign-Up or miss out</h3>
    <form [formGroup]="form" class="contact__form">
      <label for="email" class="contact__form__label">Your email</label>
      <div class="contact__form__item">
        <input
          name="email"
          formControlName="email"
          type="text"
          placeholder="Yes. This Is A Really Big Input"
          class="contact__form__input"
        />
        <button
          type="submit"
          class="contact__form__button"
          [disabled]="form.invalid"
          [class.--disabled]="form.invalid"
          (click)="sendLead()"
        >
          Suscribe
        </button>
      </div>
      <p class="contact__text">No spam. No ads. Ever. We promise.</p>
    </form>
  </div>
  <div *ngIf="leadSent" class="contact__wrapper">
    <h2 class="section__title">We will be in touch, thanks!</h2>
  </div>
</section>

<!-- SECTION CLIENTS -->
<section class="section clients">
  <div class="clients__wrapper">
    <h2 class="section__title">They can't stop smiling</h2>
    <ul class="clients__grid">
      <div class="clients__item">
        <img loading="lazy" src="/assets/images/clients/volkswagen.png" alt="volkswagen" />
      </div>
      <li class="clients__item">
        <img loading="lazy" src="/assets/images/clients/intersport.png" alt="intersport" />
      </li>
      <li class="clients__item">
        <img loading="lazy" src="/assets/images/clients/p4m.png" alt="Convercus" />
      </li>
      <li class="clients__item">
        <img loading="lazy" src="/assets/images/clients/urban.png" alt="Urban drive style" />
      </li>
      <li class="clients__item">
        <img loading="lazy" src="/assets/images/clients/iqsales.png" alt="Value IQ" />
      </li>
      <li class="clients__item">
        <img loading="lazy" src="/assets/images/clients/condor.png" alt="Condor" />
      </li>
      <li class="clients__item">
        <img loading="lazy" src="/assets/images/clients/callista.png" alt="Callista" />
      </li>
      <li class="clients__item --bg-white">
        <span class="clients__item__text">Your logo would look great here</span>
        <i-feather name="arrow-right" class="clients__item__icon"></i-feather>
      </li>
      <div class="clients__item --empty"></div>
    </ul>
  </div>
</section>

<!-- SECTION TESTIMONIALS -->

<!-- <section class="section testimonials --with-carousel">
  <div class="testimonials__wrapper">
    <h2 class="section__title">What others say about us</h2>
    <app-carousel>
      <div class="testimonials__item">
        <figure class="testimonials__item__image">
          <img src="/assets/images/testimonials/ocm.jpg" alt="" />
        </figure>
        <div class="testimonials__content">
          <p class="testimonials__text">
            "This product has a bunch of amazing tools which improves our business"
            <span class="testimonials__text__name">Maya Halin</span>
          </p>
        </div>
      </div>
      <div class="testimonials__item">
        <figure class="testimonials__item__image">
          <img src="/assets/images/testimonials/retailcrm.jpg" alt="" />
        </figure>
        <div class="testimonials__content">
          <p class="testimonials__text">
            "This product has a bunch of amazing tools which improves our business"
            <span class="testimonials__text__name">Maya Halin</span>
          </p>
        </div>
      </div>
      <div class="testimonials__item">
        <figure class="testimonials__item__image">
          <img src="/assets/images/testimonials/urban.jpg" alt="" />
        </figure>
        <div class="testimonials__content">
          <p class="testimonials__text">
            "This product has a bunch of amazing tools which improves our business"
            <span class="testimonials__text__name">Maya Halin</span>
          </p>
        </div>
      </div>
      <div class="testimonials__item">
        <figure class="testimonials__item__image">
          <img src="/assets/images/testimonials/urban.jpg" alt="" />
        </figure>
        <div class="testimonials__content">
          <p class="testimonials__text">
            "This product has a bunch of amazing tools which improves our business"
            <span class="testimonials__text__name">Maya Halin</span>
          </p>
        </div>
      </div>
      <div class="testimonials__item">
        <figure class="testimonials__item__image">
          <img src="/assets/images/testimonials/urban.jpg" alt="" />
        </figure>
        <div class="testimonials__content">
          <p class="testimonials__text">
            "This product has a bunch of amazing tools which improves our business"
            <span class="testimonials__text__name">Maya Halin</span>
          </p>
        </div>
      </div>
      <div class="testimonials__item">
        <figure class="testimonials__item__image">
          <img src="/assets/images/testimonials/urban.jpg" alt="" />
        </figure>
        <div class="testimonials__content">
          <p class="testimonials__text">
            "This product has a bunch of amazing tools which improves our business"
            <span class="testimonials__text__name">Maya Halin</span>
          </p>
        </div>
      </div>
    </app-carousel>
  </div>
</section> -->
