import { Component, OnInit } from "@angular/core";
import { UtilsService } from "src/app/services/utils/utils.service";
import posts, { IPost } from "src/assets/posts";
import { SeoService } from "src/app/services/seo/seo.service";

@Component({
  selector: "app-blog",
  templateUrl: "./blog.component.html",
  styleUrls: ["./blog.component.scss"],
})
export class BlogComponent implements OnInit {
  public posts: Array<IPost>;
  constructor(private utils: UtilsService, private seo: SeoService) {}

  ngOnInit() {
    this.seo.setCanonicalURL();
    this.seo.updateMeta({
      title: "Blog coara",
      description:
        "We believe in your vision We are truly passionate about innovation and disruption and we want to make your ideas become real.",
      imageUrl: "https://coara.co/assets/images/sharing/facebook.jpg",
    });
    this.posts = posts.sort((a, b) => (new Date(a.publishDate).getTime() > new Date(b.publishDate).getTime() ? -1 : 1));
  }

  public preloadProjectImage(post: IPost): void {
    if (post) {
      this.utils.preloadImage([post.images[0].image]);
    }
  }
}
