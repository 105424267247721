import { Injectable, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Title, Meta } from "@angular/platform-browser";

@Injectable({
  providedIn: "root",
})
export class SeoService {
  private baseURL = "https://coara.co";
  constructor(@Inject(DOCUMENT) private dom: Document, private meta: Meta, private title: Title) {}

  public updateMeta({ title, description, imageUrl }: { title?: string; description?: string; imageUrl?: string }): void {
    if (title) {
      this.meta.updateTag({ property: "og:title", content: title });
      this.title.setTitle(title);
    }
    if (description) {
      this.meta.updateTag({ name: "description", content: description });
      this.meta.updateTag({ property: "og:description", content: description });
    }
    if (imageUrl) {
      const url = `${this.baseURL}/${imageUrl}`;
      this.meta.updateTag({ property: "og:image", content: url });
      this.meta.updateTag({ property: "og:image:secure_url", content: url });
    }
  }

  public setCanonicalURL(): void {
    const url = this.dom.URL;
    const oldCannonical = this.dom.querySelectorAll("[rel='canonical']");
    if (oldCannonical.length > 0) oldCannonical[0].remove();
    const link: HTMLLinkElement = this.dom.createElement("link");
    link.setAttribute("rel", "canonical");
    this.dom.head.appendChild(link);
    link.setAttribute("href", url);
    this.meta.updateTag({ property: "og:url", content: url });
  }
}
