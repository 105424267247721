// tslint:disable: max-line-length
import { DOCUMENT } from "@angular/common";
import { Component, Inject, NgZone, OnInit } from "@angular/core";
import { SeoService } from "src/app/services/seo/seo.service";

@Component({
  selector: "app-what",
  templateUrl: "./what.component.html",
  styleUrls: ["./what.component.scss"],
})
export class WhatComponent implements OnInit {
  public statetoshow = "ideation";
  private slides: NodeListOf<Element>;

  constructor(
    private ngZone: NgZone,
    @Inject("isBrowser") private isBrowser: boolean,
    @Inject(DOCUMENT) private document: Document,
    private seo: SeoService,
  ) {}

  ngOnInit() {
    this.seo.updateMeta({
      title: "coara - what we do",
      description:
        "We believe in your vision We are truly passionate about innovation and disruption and we want to make your ideas become real.",
      imageUrl: "https://coara.co/assets/images/sharing/facebook.jpg",
    });
    this.seo.setCanonicalURL();
    this.slides = this.document.querySelectorAll(".-slide");
    if (this.isBrowser) {
      window.addEventListener("scroll", this.onWindowScroll.bind(this), true);
    }
  }

  private onWindowScroll(): void {
    const offset = window.innerWidth > 700 ? 400 : 0;
    this.slides.forEach(slide => {
      if (
        slide.getBoundingClientRect().top <= offset &&
        slide.getBoundingClientRect().bottom >= offset &&
        this.statetoshow !== slide.getAttribute("statetoshow")
      ) {
        this.ngZone.run(() => (this.statetoshow = slide.getAttribute("statetoshow")));
      }
    });
  }
}
