export interface IProject {
  uri: string;
  title: string;
  subtitle: string;
  shortDescription: string;
  longDescription: Array<string>;
  tecnologies: Array<string>;
  contrastColor: string;
  backgroundColor: string;
  categories: Array<string>;
  images: Array<{ image: string; alt: string }>;
  thumbnail: { image: string; alt: string };
  link?: string;
}

const projects: Array<IProject> = [
  {
    uri: "jericommerce",
    title: "Jericommerce",
    subtitle: "Solutions built to boost sales and engagement for E-commerce and retailers.",
    tecnologies: ["Angular", "Ionic", "Java", "Redux", "Typescript", "Mongo", "PostgreSQL"],
    shortDescription:
      "JeriPass - Apple and Android Wallet Pass creator from Jericommerce. Empowering retailers in the digital era by providing innovative and customized mobile shopping experiences.",
    longDescription: [
      "Jericommerce is a product of coara that specialize in building functional Apps for E-commerce and retails software solutions, catering to the diverse demands of various retail businesses. Committed to delivering feature-rich functionalities, seamless integrations, and custom modules that address the unique needs of different niches within the retail industry.",
      "Jericommerce strive to be the leading provider of custom mobile shopping experiences, enabling retailers to thrive in the digital landscape. Recognizing the shift towards mobile and omnichannel retailing, Jericommerce aims to empower businesses to effectively reach and engage customers across multiple touchpoints.",
      "Through continuous innovation, seamless integrations, and a focus on user experience, Jericommerce envisions a future where retailers can effortlessly adapt andsucceed in the ever-evolving e-commerce ecosystem.",
    ],
    link: "https://jericommerce.com",
    images: [
      {
        image: "assets/images/works/jericommerce/1",
        alt: "jericommerce web",
      },
      {
        image: "assets/images/works/jericommerce/2",
        alt: "jericommerce web",
      },
      {
        image: "assets/images/works/jericommerce/3",
        alt: "jericommerce web",
      },
    ],
    thumbnail: {
      image: "assets/images/works/jericommerce/1",
      alt: "Jericommerce web thumbnail",
    },
    contrastColor: "--light-color-1",
    backgroundColor: "-is-baroon-background-color",
    categories: ["UI", "UX", "Startup", "Digitization", "Mobile App", "Web App"],
  },
  {
    uri: "riscbal",
    title: "Riscbal",
    subtitle: "Natural Risk Assesment and Emergencies App and tool.",
    tecnologies: ["Angular", "Ionic", "PostGis", "ngrx", "PostgreSQL", "NestJS"],
    shortDescription: "Riscbal is a natural Risk Assesment and Emergencies App and tool which we have helped to develop.",
    longDescription: [
      "The Balearic Islands are exposed to natural hazards, which makes them a highly vulnerable territory. In recent decades, global change processes and, in particular, climate change have led to a considerable increase in these dangers: floods, erosion, desertification, forest fires, mass movements, extreme weather phenomena, etc. All of this means a considerable increase in the risks and losses resulting from natural and man-made disasters. In addition, the degree of artificialization in the territory of the Balearic Islands has increased significantly, increasing the degree of exposure of the population and infrastructure to natural and man-made hazards.",
      "Riscbal needed an App that allowed it's users to manage this through a real-time, user-friendly App. At coara we listened to the needs of Riscbal, and developed a custom App and tool that provides a seamless User experience.",
    ],
    link: "https://riscbal.uib.cat/  ",
    images: [
      {
        image: "assets/images/works/riscbal/1",
        alt: "Riscbal web",
      },
      {
        image: "assets/images/works/riscbal/2",
        alt: "Riscbal web",
      },
      {
        image: "assets/images/works/riscbal/3",
        alt: "Riscbal web",
      },
    ],
    thumbnail: {
      image: "assets/images/works/riscbal/1",
      alt: "Riscbal web thumbnail",
    },
    contrastColor: "--light-color-1",
    backgroundColor: "-is-baroon-background-color",
    categories: ["UI", "UX", "Startup", "Digitization", "Mobile App", "Web App"],
  },
  {
    uri: "wowfamily",
    title: "Wow Family",
    subtitle: "Networking App & tool that uses algorithms to optimize services industry businesses",
    tecnologies: ["Angular", "Ionic", "NestJS", "ngrx", "PostgreSQL"],
    shortDescription:
      "We developed an App and tool that using algorithms detects bottlenecks and opportunties of improvement to optimize business and improve performance.",
    longDescription: [
      "The Community of successful Hairstylists in Germany Our Mission: Empowering hairstylists to get more out of their salons and their lives. We help to gain more customers, increase profits, and have more leisure time.",
      "The idea behind this tool was to create a network that can provide added value besides connecting businesses from the same sector. By providing tools and resources that serve to optimize business thanks to shared knowledge and technology, WoW family aims to provide holistic solutions for the service industry. Also by being involved in the network, the business ensures visibility and compliance of quality standards.",
      "Imagine having access to the experience and knowledge of the most successful hairsalons in Germany. Learn more in Wowfamily.de.",
    ],
    link: "https://app.wowfamily.de ",
    images: [
      {
        image: "assets/images/works/wowfamily/1",
        alt: "wow family web",
      },
      {
        image: "assets/images/works/wowfamily/2",
        alt: "wow family web",
      },
      {
        image: "assets/images/works/wowfamily/3",
        alt: "wow family web",
      },
    ],
    thumbnail: {
      image: "assets/images/works/wowfamily/1",
      alt: "Wowfamily web thumbnail",
    },
    contrastColor: "--light-color-1",
    backgroundColor: "-is-baroon-background-color",
    categories: ["UI", "UX", "Startup", "Digitization", "Mobile App", "Web App"],
  },
  {
    uri: "planm8",
    title: "Plan M8",
    subtitle: "Yacht maintenance software",
    tecnologies: ["Angular", "Ionic", "NestJS"],
    shortDescription:
      "A simple-to-use tailor-made smartphone application designed for marine engineers, captains and owners, and operators",
    longDescription: [
      "Planned maintenance for yachts made easy. Plan M8 is specifically modeled for individual vessels, sail, or power. It provides a comprehensive and structured task-based scheduling of all required, recommended, and manufacturer-specific planned maintenance tasks of a vessel’s components.",
      "We found this project of superyacht engineer and co-founder Chris Clarke so exciting, that it is the first one to enter the coara incubator.",
      "The aim was to create a “Trello for boat maintenance”. We have simplified what is highly technical into an app that runs on the engineer’s mobile phone. A boat is structured in systems that have components. Each component has planned maintenance tasks that are triggered by date or operation hours. A clever calendar view helps in the day to day operation, a logbook creates transparency. Are you interested to become an early adopter? Just sign up on the Plan M8 web.",
    ],
    link: "https://planm8.io",
    images: [
      {
        image: "assets/images/works/planm8/planm8-1",
        alt: "planm8 Web",
      },
      {
        image: "assets/images/works/planm8/planm8-2",
        alt: "planm8 Web",
      },
      {
        image: "assets/images/works/planm8/planm8-3",
        alt: "planm8 Web",
      },
    ],
    thumbnail: {
      image: "assets/images/works/planm8/planm8-1",
      alt: "Plan M8 web thumbnail",
    },
    contrastColor: "--light-color-1",
    backgroundColor: "-is-baroon-background-color",
    categories: ["UI", "UX", "Startup", "Digitization", "Mobile App", "Web App"],
  },
  {
    uri: "baroon",
    title: "Baroon",
    subtitle: "Property Management & Communication",
    tecnologies: ["Angular", "Ionic", "NestJS"],
    shortDescription: "Baroon is a digital property management platform startup which we have helped to develop",
    longDescription: [
      "Baroon digitized communication between property management companies and owners of apartments.",
      "The company is a Swiss Startup founded in 2020.",
      "We were selected by the founders to design their mobile and web apps for property owners and managers.",
      "We have run a Product definition workshop and prepared the UX and UI Design and then implemented \
       a mobile app for the property owners and a web app for the property management company.",
      "Objective of both are the improvement of communication between all parties.",
    ],
    link: "https://baroon.io",
    images: [
      {
        image: "assets/images/works/baroon/baroon-1",
        alt: "baroon Web",
      },
      {
        image: "assets/images/works/baroon/baroon-2",
        alt: "baroon Web",
      },
      {
        image: "assets/images/works/baroon/baroon-3",
        alt: "baroon Web",
      },
    ],
    thumbnail: {
      image: "assets/images/works/baroon/baroon-1",
      alt: "Callista Web thumbnail",
    },
    contrastColor: "--light-color-1",
    backgroundColor: "-is-baroon-background-color",
    categories: ["UI", "UX", "Startup", "Digitization", "Mobile App", "Web App"],
  },

  {
    uri: "retailcrm",
    title: "Retail CRM Cloud",
    subtitle: "CRM Suite for Retailers",
    tecnologies: ["Loyaly", "Retailer Mobile App", "Sales Rep App", "Analytics", "Marketing"],
    shortDescription:
      "The swiss army knife for retailers. Transform your stores from places of pure transaction, \
      to being places where you build a relationship with shoppers, regardless if they tend to shop online or offline.",
    longDescription: [
      "Retail CRM Cloud helps you to have the best of both worlds - terrific online operation and strong physical presence.",
      "The project was a consequence of our year long building of products and services for the retail industry.",
      "We help retailers to grow by seamlessly integrating loyalty solutions, mobile apps and sales rep support tools.",
      "Retail CRM cloud is a modular system based on powerful APIs. It can be integrated with many ERP systems,\
       mailing providers, payment platforms, online stores, loyalty programs, etc.",
    ],
    link: "https://retailcrm.cloud",
    images: [
      {
        image: "assets/images/works/retailcrm/retailcrm-1",
        alt: "sales rep app",
      },
      {
        image: "assets/images/works/retailcrm/retailcrm-2",
        alt: "sales ticket",
      },
    ],
    thumbnail: {
      image: "assets/images/works/retailcrm/retailcrm-1",
      alt: "Retail CRM Cloud Thumbnail",
    },
    contrastColor: "--light-color-1",
    backgroundColor: "-is-baroon-background-color",
    categories: ["Startup", "Digitization", "Retail", "Mobile App"],
  },
  {
    uri: "cleanwave",
    title: "Cleanwave",
    subtitle: "#plasticfreemovement",
    tecnologies: ["Angular", "Ionic", "NestJS"],
    shortDescription: "A non-profit initiative against single-use plastic",
    longDescription: [
      "Ride the wave towards a plastic free world!",
      "Cleanwave has installed a network of drinking water fountains for free use around the Balearic Islands.",
      "We have supported cleanwave with the creation of a mobile app to quickly locate the closest fountain.",
      "By June 2020 Cleanwave has saved 4.5M plastiv bottles!",
    ],
    link: "https://cleanwave.org",
    images: [
      {
        image: "assets/images/works/cleanwave/cleanwave1",
        alt: "Cleanwave Web",
      },
      {
        image: "assets/images/works/cleanwave/cleanwave2",
        alt: "Cleanwave Web",
      },
    ],
    thumbnail: {
      image: "assets/images/works/cleanwave/cleanwave1",
      alt: "Cleanwave Web thumbnail",
    },
    contrastColor: "--light-color-1",
    backgroundColor: "-is-cleanwave-background-color",
    categories: ["#sustainability", "#plasticfreemovement"],
  },
  {
    uri: "callista-web-redesign",
    title: "Callista",
    subtitle: "Web Redesign",
    tecnologies: ["HTML5", "SCSS", "Angular", "SEO"],
    shortDescription: "We had the challenge to renovate the image of Callista as an ideal partner for digital transformation.",
    longDescription: [
      "Callista is a swiss consulting company with customers like UBS, Credit Suisse or Zürich Insurance.",
      "The company is historically specialized in data engineering and business intelligence.",
      "Over the last years their expertise has broadened into the digitization of corporations and agile project management consulting.",
      "Our aim was to renew the image of the company in alignment with this new positioning.",
      "We have created a new web design maintaining the brand values. We also helped Callista to \
      introduce new communication technologies and to develop new business models around digital transformation.",
    ],
    link: "https://callista.group",
    images: [
      {
        image: "assets/images/works/callista/callista6",
        alt: "Callista Web",
      },
      {
        image: "assets/images/works/callista/callista1",
        alt: "Callista Web",
      },
      {
        image: "assets/images/works/callista/callista4",
        alt: "Callista Web",
      },
      {
        image: "assets/images/works/callista/callista3",
        alt: "Callista Web",
      },
      {
        image: "assets/images/works/callista/callista2",
        alt: "Callista Web",
      },
      {
        image: "assets/images/works/callista/callista5",
        alt: "Callista Web",
      },
    ],
    thumbnail: {
      image: "assets/images/works/callista/callista_thumbnail",
      alt: "Callista Web thumbnail",
    },
    contrastColor: "--dark-color-1",
    backgroundColor: "-is-callista-background-color",
    categories: ["UI", "Web Frontend", "Digitization"],
  },
  {
    uri: "ocm",
    title: "OCM",
    subtitle: "POS App for loyalty platform",
    tecnologies: ["Angular", "PWA", "Ionic", "Socket.io", "NodeJS"],
    shortDescription: "Creation of a Point of sales and end user applications for OCM loyalty platform.",
    longDescription: [
      "The POS application is installed at cash registers of retail stores and\
       asks clients to scan their loyalty card at each transaction. It is also installed\
       as service station where clients can check their status, rewards and benefits.",
      "The USER application is designed for the store's clients and coantains the loyalty\
      program for the end user (QR code, rewards, news, shopping, ...)",
      "We have created a set of modern Ionic/Angular Apps which run as Progressive Web App on desktop\
      or mobile devices, or as compiled apps on Android and iOS",
      "All apps are connected with the convercus backend via REST APIs and socket.io connections for\
      immediate interaction. The user apps further support push notifications. The POS app can\
      connect with ticket printers",
      "By using typescript and classes the app is completely customizable for each store or loyalty program.",
      "Devices are managed centrally through socket connections and device managemet software.",
    ],
    images: [
      {
        image: "assets/images/works/ocm/ocm1",
        alt: "Convercus / Outlet City",
      },
      {
        image: "assets/images/works/ocm/ocm2",
        alt: "Convercus / Outlet City",
      },
      {
        image: "assets/images/works/ocm/ocm3",
        alt: "Convercus / Outlet City",
      },
      {
        image: "assets/images/works/ocm/ocm4",
        alt: "Convercus / Outlet City",
      },
    ],
    thumbnail: {
      image: "assets/images/works/ocm/ocm_thumbnail",
      alt: "OCM Web thumbnail",
    },
    contrastColor: "--light-color-1",
    backgroundColor: "-is-point4more-background-color",
    categories: ["Frontend", "Backend", "API"],
  },
  {
    uri: "boneo",
    title: "Boneo",
    subtitle: "Loyalty platform MVP",
    tecnologies: ["Angular", "PWA", "Ionic", "Socket.io", "NodeJS"],
    shortDescription: "An MVP for a loyalty program pilot for Volkswagen Group.",
    longDescription: [
      "We have participated in a Volkswagen Group pilot for the introduction of a loyalty program. \
      Our responsability was the creation of the client interface on tablets,\
      that were installed at the POS of participating dealers.",
      "The application is built as Progressive Web App and connected with the Convercus loyalty platform.",
      "The app was up and running in just six weeks from initial scope definition.",
    ],
    images: [
      {
        image: "assets/images/works/boneo/boneo1",
        alt: "BONEO",
      },
      {
        image: "assets/images/works/boneo/boneo2",
        alt: "BONEO",
      },
      {
        image: "assets/images/works/boneo/boneo3",
        alt: "BONEO",
      },
      {
        image: "assets/images/works/boneo/boneo4",
        alt: "BONEO",
      },
    ],
    thumbnail: {
      image: "assets/images/works/boneo/boneo_thumbnail",
      alt: "boneo Web thumbnail",
    },
    contrastColor: "--light-color-1",
    backgroundColor: "-is-boneo-background-color",
    categories: ["Frontend", "HTML/SCSS"],
  },
  {
    uri: "intersport",
    title: "Intersport",
    subtitle: "API gateway and landing page",
    tecnologies: ["NodeJS", "REST", "AWS Aurora", "HTML5", "SCSS"],
    shortDescription:
      "Creation of an API gateway between the Intersport loyalty platform and payment provider e-pay with a\
      customer frontend respecting the Intersport brand.",
    longDescription: [
      "Intersport Club the company's loyalty program is based on the Convercus loyalty platform. We have developed\
    an API gateway that connects Convercus with the payment card provider e-pay and enables customers to\
    transform loyalty points in e-pay gift cards.",
      "As the gateway is handling customer money, this project requires fail save REST API connections, \
      logging and error tracking.",
      "In most of our projects we automatically track each application or server error and monitor and resolve\
      potential code errors automatically. We also implement unit tests for all code. For this project in particular, we \
      have additionally implemented a complete logging with rollback procedures and alerts for all payment relevant processes.",
    ],
    images: [
      {
        image: "assets/images/works/intersport/intersport4",
        alt: "Intersport",
      },
      {
        image: "assets/images/works/intersport/intersport5",
        alt: "Intersport",
      },
      {
        image: "assets/images/works/intersport/intersport1",
        alt: "Intersport",
      },
      {
        image: "assets/images/works/intersport/intersport2",
        alt: "Intersport",
      },
      {
        image: "assets/images/works/intersport/intersport3",
        alt: "Intersport",
      },
      {
        image: "assets/images/works/intersport/intersport6",
        alt: "Intersport",
      },
    ],
    thumbnail: {
      image: "assets/images/works/intersport/intersport_thumbnail",
      alt: "intersport Web thumbnail",
    },
    contrastColor: "--light-color-1",
    backgroundColor: "-is-intersport-background-color",
    categories: ["UI", "Web", "API Gateway"],
  },
  {
    uri: "iq-sales",
    title: "IQ Sales - Retail CRM",
    subtitle: "Creation of CRM platform for retail",
    tecnologies: ["Ionic", "NodeJS", "Elastic Search", "Mongo DB", "REST API", "GraphQL API"],
    shortDescription:
      "IQ Sales is a CRM for retailers. Designed for sales reps to enable them to deliver\
      the best possible personal shopping experience to their customers.",
    longDescription: [
      "We have developed the project end to end, UX, UI, mobile app, progressive web app, website, replicated NodeJS servers with\
      Mongo DB and Elastic Search.",
      "The multi tenant CRM is fully configurable and is a software platform that can be adopted to any retailers need.",
    ],

    link: "https://iqsales.cloud",
    images: [
      {
        image: "assets/images/works/iq-sales/iq-sales3",
        alt: "IQ Sales",
      },
      {
        image: "assets/images/works/iq-sales/iq-sales4",
        alt: "IQ Sales",
      },
      {
        image: "assets/images/works/iq-sales/iq-sales1",
        alt: "IQ Sales",
      },
      {
        image: "assets/images/works/iq-sales/iq-sales2",
        alt: "IQ Sales",
      },
      {
        image: "assets/images/works/iq-sales/iq-sales5",
        alt: "IQ Sales",
      },
      {
        image: "assets/images/works/iq-sales/iq-sales6",
        alt: "IQ Sales",
      },
    ],
    thumbnail: {
      image: "assets/images/works/iq-sales/iq-sales_thumbnail",
      alt: "iq-sales Web thumbnail",
    },
    contrastColor: "--dark-color-1",
    backgroundColor: "-is-iq-sales-background-color",
    categories: ["Concept", "UX", "UI", "APP", "Backend", "Product"],
  },
  {
    uri: "condor",
    title: "Condor",
    subtitle: " Campaign Management Dashboard",
    tecnologies: ["Tableau", "NodeJS", "PosgreSQL", "Azure Active Directory"],
    shortDescription: "Tableau dashboard connecting with google and facebook marketing api.",
    longDescription: [
      "This project organizes thousands of google, facebook and offline campaigns.",
      "Ads are categorized by origin (airport / city / region / country) and destination. \
      Offline campaigns can be added via file upload or manually.",
      "The combined information is then visualised with the Business Intelligence tool Tableau.",
    ],

    images: [
      {
        image: "assets/images/works/condor/condor1",
        alt: "Condor tableau",
      },
      {
        image: "assets/images/works/condor/condor2",
        alt: "Condor tableau",
      },
    ],
    thumbnail: {
      image: "assets/images/works/condor/condor_thumbnail",
      alt: "condor Web thumbnail",
    },
    contrastColor: "--dark-color-1",
    backgroundColor: "-is-condor-background-color",
    categories: ["API", "Business Intelligence", "Backend"],
  },
  {
    uri: "urban-drivestyle",
    title: "Urban Drivestyle",
    subtitle: "Mobile App and IoT solution for e-bike sharing",
    tecnologies: ["Ionic", "Firebase", "HTML5", "SCSS", "MBED IoT", "GPS", "GPRS", "BLE"],
    shortDescription:
      "Together with the e-bike startup Urban Drivestyle we have developed a sharing solution for station\
      based lifestyle e-bikes.",
    longDescription: [
      "Besides the development of a mobile app in Urban Drivestyle design and a sharing management platform,\
      the main challenge of this project is the development of IoT hardware.",
      "We have created a module for bike tracking, lock management and bike status monitoring.\
      Devices communicate via GSM or Sigfox network with our servers.",
      "We have implemented a complete fleet management solution.",
    ],
    images: [
      {
        image: "assets/images/works/urban-drivestyle/urban5",
        alt: "Urban drivestyle",
      },
      {
        image: "assets/images/works/urban-drivestyle/urban2",
        alt: "Urban drivestyle",
      },
      {
        image: "assets/images/works/urban-drivestyle/urban1",
        alt: "Urban drivestyle",
      },
      {
        image: "assets/images/works/urban-drivestyle/urban3",
        alt: "Urban drivestyle",
      },
      {
        image: "assets/images/works/urban-drivestyle/urban4",
        alt: "Urban drivestyle",
      },
      {
        image: "assets/images/works/urban-drivestyle/urban6",
        alt: "Urban drivestyle",
      },
    ],
    thumbnail: {
      image: "assets/images/works/urban-drivestyle/urban_thumbnail",
      alt: "urban-drivestyle Web thumbnail",
    },
    contrastColor: "--light-color-1",
    backgroundColor: "-is-urban-background-color",
    categories: ["UI", "UX", "APP", "Backend", "Hardware"],
  },
  {
    uri: "coara-iot",
    title: "coara IoT",
    subtitle: "Asset tracking portal",
    tecnologies: ["Sigfox", "GSM", "BLE", "GPS", "Firebase"],
    shortDescription:
      "We have developed a portal for asset tracking, control and interaction. The platform supports any kind of mobile assets, \
      like containers, vehicle fleets or share bikes.",
    longDescription: [
      "For this project we connect IoT hardware over different networks like Sigfox, GPRS or Narrow Band LTE,\
      with our servers.",
      "The IoT devices can be attached to any kind of asset. Assets are then visualized according their type \
      with different information (like driver of a vehicle or content of a container).",
      "The portal is multi-tenant in a way that assets are assigned to different accounts.",
      "It has integrated standard functionality,\
       like search for a specific device or location of the closest vehicle to a given\
      location, and can be individually extended with plugins.",
      "Our portal is open to any kind of network and can be configured for client needs.",
    ],
    images: [
      {
        image: "assets/images/works/iot/iot2",
        alt: "Urban drivestyle",
      },
      {
        image: "assets/images/works/iot/iot5",
        alt: "Urban drivestyle",
      },
      {
        image: "assets/images/works/iot/iot1",
        alt: "Urban drivestyle",
      },
      {
        image: "assets/images/works/iot/iot3",
        alt: "Urban drivestyle",
      },
      {
        image: "assets/images/works/iot/iot4",
        alt: "Urban drivestyle",
      },
      {
        image: "assets/images/works/iot/iot6",
        alt: "Urban drivestyle",
      },
    ],
    thumbnail: {
      image: "assets/images/works/iot/iot_thumbnail",
      alt: "iot Web thumbnail",
    },
    contrastColor: "--light-color-1",
    backgroundColor: "-is-coara-iot-background-color",
    categories: ["Concept", "UX", "UI", "Backend", "Frontend", "IoT Hardware"],
  },
  {
    uri: "bttr",
    title: "bttr - agile retrospective tool",
    subtitle: "Improve yourself. Improve your team.",
    tecnologies: ["Angular", "HTML5", "SCSS", "Python"],
    shortDescription: "A tool that makes Agile SCRUM restrospectives efficient and fun.",
    longDescription: [
      "Increase participation in restrocpectives, focus on the important topics, improve constantly.",
      "bttr was created as a solution for remote team workers, always in movement, 'mobile first'. With this priority, we have designed \
      a progressive web app.",
      "The concept is based on Agile SCRUM retrospectives, \
      but it can be used in any team and project management environment, \
      not just SCRUM.",
      "We have created a simple and clear design, the aim is to see the health of the team in an intuitive way.",
      "Traffic light colors classify the feedback at first glance.",
      "For more details, the team can rate every sprint from 0 to 10 and add a comment.",
      "Finally, you can see how the team is improving week by week.",
    ],
    images: [
      {
        image: "assets/images/works/bttr/bttr6",
        alt: "bttr app",
      },
      {
        image: "assets/images/works/bttr/bttr3",
        alt: "bttr app",
      },
      {
        image: "assets/images/works/bttr/bttr1",
        alt: "bttr app",
      },
      {
        image: "assets/images/works/bttr/bttr2",
        alt: "bttr app",
      },
      {
        image: "assets/images/works/bttr/bttr4",
        alt: "bttr app",
      },
      {
        image: "assets/images/works/bttr/bttr5",
        alt: "bttr app",
      },
    ],
    thumbnail: {
      image: "assets/images/works/bttr/bttr_thumbnail",
      alt: "bttr Web thumbnail",
    },
    link: "https://bttr.app",
    contrastColor: "--dark-color-1",
    backgroundColor: "-is-bttr-background-color",
    categories: ["UI", "UX", "Frontend", "Backend", "Idea"],
  },
  {
    uri: "dhs",
    title: "Daily Health Secret",
    subtitle: "Health lifestyle in just 40 seconds a day",
    tecnologies: ["APP", "Ionic", "Angular", "Firebase"],
    shortDescription: "Creation of interactive mobile app based on customer's design.",
    longDescription: [
      "We built this project according to the wishes of our customer 123prefectlife Ltd. as hybrid mobile app with a firebase backend.",
      "It includes features like in app notifications, real time database voucher system and social media shares and invitations.",
    ],
    images: [
      {
        image: "assets/images/works/dhs/dhs1",
        alt: "Daily Health Secret",
      },
      {
        image: "assets/images/works/dhs/dhs4",
        alt: "Daily Health Secret",
      },
      {
        image: "assets/images/works/dhs/dhs2",
        alt: "Daily Health Secret",
      },
      {
        image: "assets/images/works/dhs/dhs5",
        alt: "Daily Health Secret",
      },
    ],
    thumbnail: {
      image: "assets/images/works/dhs/dhs_thumbnail",
      alt: "dhs Web thumbnail",
    },
    contrastColor: "--dark-color-1",
    backgroundColor: "-is-dhs-background-color",
    categories: ["APP", "Backend"],
  },
];

export default projects;
