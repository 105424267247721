import { Component, OnInit } from "@angular/core";
import { SeoService } from "src/app/services/seo/seo.service";

@Component({
  selector: "app-incubator",
  templateUrl: "./incubator.component.html",
  styleUrls: ["./incubator.component.scss"],
})
export class IncubatorComponent implements OnInit {
  constructor(private seo: SeoService) {}

  ngOnInit() {
    this.seo.updateMeta({
      title: "coara incubator",
      description:
        "Coara is a startup building agency. We get frequently contacted by founders who have a great team and a great idea, but they still lack some ingredient to be able to build a product and thrive. This is where coara comes into play as an agency. Some of these founders and ideas are so outstanding though, that we want to have them in our coara incubator. ",
      imageUrl: "/assets/images/sharing/coara-incubator.png",
    });
    this.seo.setCanonicalURL();
  }
}
