import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SvgIterationComponent } from "./components/svg-iteration/svg-iteration.component";
import { FourHundredFourComponent } from "./pages/four-hundred-four/four-hundred-four.component";
import { HomeComponent } from "./pages/home/home.component";
import { WhatComponent } from "./pages/what/what.component";
import { ProjectComponent } from "./pages/works/project/project.component";
import { WorksComponent } from "./pages/works/works.component";
import { BlogComponent } from "./pages/blog/blog.component";
import { PostComponent } from "./pages/blog/post/post.component";
import { NgxJsonLdModule } from "@ngx-lite/json-ld";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { ButtonComponent } from "./components/button/button.component";
import { CarouselComponent } from "./components/carousel/carousel.component";
import { LogoComponent } from "./components/logo/logo.component";
import { FeatherModule } from "angular-feather";
import { ArrowRight } from "angular-feather/icons";
import { LottieModule } from "ngx-lottie";
import player from "lottie-web";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";

export function playerFactory() {
  return player;
}

const icons = {
  ArrowRight,
};

const components = [SvgIterationComponent];
@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    HomeComponent,
    WorksComponent,
    ProjectComponent,
    FourHundredFourComponent,
    WhatComponent,
    BlogComponent,
    PostComponent,
    components,
    HeaderComponent,
    ButtonComponent,
    FooterComponent,
    CarouselComponent,
    LogoComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "coara-web" }),
    AppRoutingModule,
    FeatherModule.pick(icons),
    NgxJsonLdModule,
    ServiceWorkerModule.register("ngsw-worker.js", { enabled: !!environment.production }),
    LottieModule.forRoot({ player: playerFactory }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [FeatherModule],
  providers: [
    {
      provide: "isBrowser",
      useValue: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
