import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class LeadService {
  constructor(private http: HttpClient) {}

  public async storeLead(email: string): Promise<Object> {
    return this.http
      .post("https://hook.integromat.com/fb79pal4tnzdp5ceoi170sreqbb8cusr", { email, date: Date.now(), environment: environment.name })
      .toPromise();
  }
}
