<div class="what-page">
  <div class="background-wrapper">
    <section class="what-page__claim">
      <h2 class="what-page__claim__title" i18n="what page claim title @@WhatPageClaimTitle">
        We develop digital
        <br />
        products
      </h2>
      <p class="what-page__claim__text" i18n="what page claim text @@WhatPageClaimText">
        Our core competence is building functional
        <br />
        software with great focus
        <br />
        on outstanding UX and design. Data
        <br />
        architecture, analysis, interfaces
        <br />
        and APIs are in our DNA.
      </p>
    </section>

    <section class="what-page__iteration">
      <div class="what-page__iteration__image">
        <div class="what-page__iteration__image__wrapper"></div>
      </div>
      <div class="what-page__iteration__claim">
        <p class="what-page__iteration__claim__text" i18n="what page iteration text @@WhatPageIterationText">
          Learn, build, measure, and
          <br />
          learn again... non stop.
        </p>
      </div>
    </section>

    <section class="what-page__what-we-do">
      <div class="what-page__what-we-do__wrapper">
        <h2 class="what-page__what-we-do__title" i18n="what page what we do title@@WhatPageWhatWeDoTitle">
          Building like a <span>startup</span>
        </h2>
        <p class="what-page__what-we-do__text" i18n="what page what we do text@@WhatPageWhatWeDoText">
          Our iterative development cycles are applied by <span class="underline">lean</span> startups. Let's build a clear and
          <span class="underline">awesome MVP</span>. Validate your concept with a product that everybody understands in seconds. Rapidly
          discover if a <span class="underline">business model</span> is viable! Add <span class="underline">functionality</span> when users
          ask for it, or when your product sells well. We don't over-engineer!
        </p>
        <app-button text="Get a quote" class="what-page__what-we-do__button"></app-button>
      </div>
    </section>
  </div>

  <section class="what-page__iteration-presentation">
    <div class="what-page__iteration-presentation__header">
      <h3 class="what-page__iteration-presentation__header__title">
        Iteration is the key
      </h3>
    </div>
    <div class="what-page__iteration-presentation__image-section">
      <app-svg-iteration [stateToShow]="statetoshow" class="what-page__iteration-presentation__image-section__svg"> </app-svg-iteration>
    </div>
    <ul class="what-page__iteration-presentation__slides">
      <li class="what-page__iteration-presentation__slides__slide -slide" [attr.stateToShow]="'ideation'">
        <p class="what-page__iteration-presentation__slides__slide__title">
          Ideation
        </p>
        <p class="what-page__iteration-presentation__slides__slide__text">
          Our team adds external perspective and experience to your idea. Together we deep-dive into the market and identify a clear
          consumer need.
          <br /><br />
          We build mock-ups and prototypes to test a hypothesis, and your value proposition’s positioning and the best user experience.
        </p>
      </li>
      <li class="what-page__iteration-presentation__slides__slide -slide" [attr.stateToShow]="'building'">
        <p class="what-page__iteration-presentation__slides__slide__title">
          Building
        </p>
        <p class="what-page__iteration-presentation__slides__slide__text">
          Our core expertise is data and development of user friendly software.
          <br /><br />
          We create an awesome minimum viable product which is integrated into your data architecture. The MVP helps you to verify your
          hypothesis and through iterations becomes the base for your future product.
        </p>
      </li>
      <li class="what-page__iteration-presentation__slides__slide -slide" [attr.stateToShow]="'traction'">
        <p class="what-page__iteration-presentation__slides__slide__title">
          Traction
        </p>
        <p class="what-page__iteration-presentation__slides__slide__text">
          A new product only becomes valuable when it starts to create traction (users and revenue).
          <br /><br />
          We know the tricks to get your product up and running in the market. We constantly growth hack and improve the it for a better
          user experience.
        </p>
      </li>
    </ul>
  </section>
</div>
