import { Component, OnInit } from "@angular/core";
import projects, { IProject } from "src/assets/projects";
import { UtilsService } from "src/app/services/utils/utils.service";
import { SeoService } from "src/app/services/seo/seo.service";

@Component({
  selector: "app-works",
  templateUrl: "./works.component.html",
  styleUrls: ["./works.component.scss"],
})
export class WorksComponent implements OnInit {
  public projects: Array<IProject>;
  constructor(private utils: UtilsService, private seo: SeoService) {}

  ngOnInit() {
    this.projects = [...projects];
    this.seo.setCanonicalURL();
    this.seo.updateMeta({
      title: "coara - Our Works",
      description: "Enjoy a selection of our latest projects.",
      imageUrl: "https://coara.co/assets/images/sharing/facebook.png",
    });
  }

  public preloadProjectImage(project: IProject): void {
    if (project) this.utils.preloadImage([project.images[0].image]);
  }

  public trackBy(index: string, project: IProject): string {
    return `${index}-${project.uri}`;
  }
}
