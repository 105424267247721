import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import { PATHS, ROUTES } from "../constants";
import { environment } from "../environments/environment";
import { getCLS, getFID, getLCP } from "web-vitals";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  public ROUTES = ROUTES;
  public PATHS = PATHS;
  public sideRedering: "server-side" | "browser-side";
  public actualPath: string;
  private animationsEnabled: boolean;
  constructor(private router: Router, @Inject("isBrowser") private isBrowser: boolean, @Inject(DOCUMENT) private document: Document) {}

  ngOnInit() {
    if (this.isBrowser) {
      getCLS(this.sendToGoogleAnalytics);
      getFID(this.sendToGoogleAnalytics);
      getLCP(this.sendToGoogleAnalytics);
      this.animationsEnabled = this.areAnimationsEnabled();
      this.sideRedering = "browser-side";
      window.addEventListener("scroll", this.onWindowScroll.bind(this), true);
      (<any>window).ga("create", environment.analyticsId, "auto");
      this.router.events.subscribe(event => {
        if (event instanceof NavigationStart) {
          window.scrollTo(0, 0);
        }
        if (event instanceof NavigationEnd) {
          this.actualPath = `-${event.urlAfterRedirects.split("/").join("-")}`;
          (<any>window).ga("set", "page", event.urlAfterRedirects);
          (<any>window).ga("send", "pageview");
        }
      });
    } else {
      this.sideRedering = "server-side";
    }
  }

  private areAnimationsEnabled(): boolean {
    if (this.isBrowser) {
      return !(navigator.userAgent.match("Edge") || navigator.userAgent.match("Mobile"));
    } else {
      return false;
    }
  }

  private onWindowScroll(): void {
    if (this.animationsEnabled && this.isBrowser) {
      this.document.body.style.setProperty("--scroll-Y", `-${window.scrollY}px`);
    }
  }

  private sendToGoogleAnalytics({ name, delta, id }: { name: string; delta: number; id: string }) {
    (<any>window).ga("send", "event", {
      eventCategory: "Web Vitals",
      eventAction: name,
      eventLabel: id,
      eventValue: Math.round(name === "CLS" ? delta * 1000 : delta),
      nonInteraction: true,
      transport: "beacon",
    });
  }
}
