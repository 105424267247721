import { Component, Inject, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-svg-iteration",
  templateUrl: "./svg-iteration.component.html",
  styleUrls: ["./svg-iteration.component.scss"],
})
export class SvgIterationComponent implements OnInit {
  @Input()
  stateToShow: "ideation" | "building" | "traction" | "all" = "all";
  public path: string;
  constructor(@Inject("isBrowser") private isBrowser: boolean) {
    if (this.isBrowser) {
      this.path = window.location.href;
    }
  }

  ngOnInit() {}

  public svgFill(gradient: string): string {
    return `url(${this.path}${gradient})`;
  }
}
