<div class="works-page">
  <div class="works-page__text">
    <h1 class="works-page__text__subtitle" i18n="subtitle header works page@@subtitleHeaderWorks">
      Enjoy a selection <br />
      of our <span>projects.</span>
    </h1>
  </div>

  <div class="works-page__projects">
    <div class="works-page__projects__wrapper">
      <div
        *ngFor="let project of projects; let i = index; trackBy: trackBy"
        class="works-page__projects__item"
        [class.--no-animation]="i > 8"
        [style.animationDuration]="'1.' + i + 's'"
        [style.animationDelay]="'0.' + i + 's'"
      >
        <a
          [routerLink]="[project.uri]"
          class="works-page__projects__item__image"
          title="project page"
          (mouseenter)="preloadProjectImage(project)"
        >
          <picture>
            <source *ngFor="let type of ['webp', 'jpg']" srcset="{{project?.thumbnail?.image + '.' + type}}" type="image/{{ type }}" />
            <img [attr.loading]="i <= 2 ? 'eager' : 'lazy'" src="{{ project?.thumbnail?.image }}.jpg" alt="{{ project?.thumbnail?.alt }}" />
          </picture>
          <p class="works-page__projects__item__image__text">
            <span class="works-page__projects__item__image__text__title">
              {{ project?.title }}
            </span>
            <span class="works-page__projects__item__image__text__tags">
              <span *ngFor="let category of project.categories"> {{ category }} <em>,</em> </span>
            </span>
          </p>
        </a>
      </div>
    </div>
  </div>
</div>
