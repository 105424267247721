import { Component, OnInit } from "@angular/core";
import { PATHS, ROUTES } from "src/constants";
import projects from "src/assets/projects";
import { UtilsService } from "src/app/services/utils/utils.service";
import { SeoService } from "src/app/services/seo/seo.service";
import { AnimationItem } from "lottie-web";
import { AnimationOptions } from "ngx-lottie";
import { IProject } from "src/assets/projects";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { LeadService } from "src/app/services/lead/lead.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  // ANIMATIONS
  mouseAnimation: AnimationOptions = {
    path: "/assets/animations/mouse.json",
  };
  infiniteCirclesAnimation: AnimationOptions = {
    path: "/assets/animations/infinite-circles.json",
  };
  labyrinthAnimation: AnimationOptions = {
    path: "/assets/animations/labyrinth.json",
  };

  public ROUTES = ROUTES;
  public PATHS = PATHS;
  public projectsToShow: IProject[];
  public form: FormGroup;
  public leadSent: boolean;
  public quotes: Array<{ index: number; text: string; autor: string }> = [
    {
      index: 1,
      text: "If you're afraid of failure or getting it wrong, you'll never truly <span>innovate</span> or disrupt the market.",
      autor: "Gillian Tans, CEO booking.com",
    },
    {
      index: 2,
      text: "One of the only ways to get out of a tight box is to <span>invent</span> your way out.",
      autor: "Jeff Bezos, CEO amazon.com",
    },
    {
      index: 3,
      text: "My view is there's no bad time to <span>innovate.</span>",
      autor: "Jeff Bezos, CEO amazon.com",
    },
    {
      index: 4,
      text: "<span>Innovation</span> is the calling card of the future.",
      autor: "Anna Eshoo",
    },
    {
      index: 5,
      text: "Few ideas work on the first try. <span>Iteration</span> is key to <span>innovation.</span>",
      autor: "Sebastian Thrun",
    },
    {
      index: 6,
      text: "The only way to thrive is to <span>innovate</span>. It's that simple.",
      autor: "Alex Tabarrok",
    },
  ];

  constructor(private utils: UtilsService, private seo: SeoService, private lead: LeadService) {}

  ngOnInit() {
    this.form = new FormGroup({ email: new FormControl(null, [Validators.required, Validators.email]) });
    this.projectsToShow = [...projects];
    this.projectsToShow.sort((a, _b) => a.uri.length - Math.floor(Math.random() * this.projectsToShow.length)).splice(0, 5);
    this.seo.updateMeta({
      title: "coara - we build digital products",
      description: "We empower founders to innovate and develop faster. From concept to digital product to market success.",
      imageUrl: "assets/images/sharing/facebook.png",
    });
    this.seo.setCanonicalURL();
    this.quotes.sort(a => a.index - Math.floor(Math.random() * this.quotes.length));
  }

  public preloadProjectImages(link: string): void {
    const project = this.projectsToShow.find(({ uri }) => !!link.match(uri));
    this.utils.preloadImage([project.images[0].image]);
  }

  private toggleClass(add: boolean, element: Element): void {
    if (add && !element.className.includes("-show-with-color")) {
      element.className = `${element.className} -show-with-color`;
    }
    if (!add) element.className = element.className.replace(/-show-with-color/g, "");
  }

  animationCreated(animationItem: AnimationItem): void {}

  public sendLead(): void {
    const { email } = this.form.value;
    this.lead.storeLead(email);
    this.leadSent = true;
  }
}
