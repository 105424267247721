import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PATHS, ROUTES } from "../constants";
import { FourHundredFourComponent } from "./pages/four-hundred-four/four-hundred-four.component";
import { HomeComponent } from "./pages/home/home.component";
import { WhatComponent } from "./pages/what/what.component";
import { ProjectComponent } from "./pages/works/project/project.component";
import { WorksComponent } from "./pages/works/works.component";
import { BlogComponent } from "./pages/blog/blog.component";
import { PostComponent } from "./pages/blog/post/post.component";
import { IncubatorComponent } from "./pages/incubator/incubator.component";

const routes: Routes = [
  {
    path: PATHS[ROUTES.HOME],
    component: HomeComponent,
  },
  {
    path: PATHS[ROUTES.WORKS],
    children: [
      {
        path: "",
        component: WorksComponent,
        pathMatch: "full",
      },
      {
        path: ":project",
        component: ProjectComponent,
        runGuardsAndResolvers: "always",
      },
    ],
  },
  {
    path: PATHS[ROUTES.WHAT],
    component: WhatComponent,
  },
  {
    path: PATHS[ROUTES.BLOG],
    children: [
      {
        path: "",
        component: BlogComponent,
        pathMatch: "full",
      },
      {
        path: ":postUri",
        component: PostComponent,
        runGuardsAndResolvers: "always",
      },
    ],
  },
  {
    path: PATHS[ROUTES.INCUBATOR],
    component: IncubatorComponent,
  },
  {
    path: PATHS[ROUTES.FOUR_HUNDRED_FOUR],
    component: FourHundredFourComponent,
  },
  {
    path: "**",
    component: FourHundredFourComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: "reload",
      relativeLinkResolution: "corrected",
      initialNavigation: "enabled",
      anchorScrolling: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
