<article class="post-main post-page">
  <div class="post-entry post-page__content">
    <div class="post-page__content__left-side">
      <nav class="post-page__content__left-side__header">
        <time [dateTime]="post.publishDate | date">{{ post.publishDate | date }}</time>
        <h1 class="post-page__content__left-side__title" [innerHTML]="post?.title"></h1>
        <div class="meta">
          <em>
            post written by <a class="author" [innerHTML]="post.writedBy"></a><br />
            <!-- <span>in Branding</span> — <a href="#">News</a> — <a href="#">Blog</a> -->
          </em>
        </div>
        <aside class="post-page__content__left-side__recents recents-posts" *ngIf="posts?.length > 0">
          <h3 class="recents-posts__title">Related Posts</h3>
          <ul class="recents-posts__list">
            <li *ngFor="let post of posts"><a [routerLink]="['/blog', post?.uri]" class="inner-link" [innerHTML]="post?.title"></a></li>
            <br />
            <li><a [routerLink]="'/' + PATHS[ROUTES.BLOG]" class="inner-link">All posts</a></li>
          </ul>
        </aside>
      </nav>
    </div>
    <div class="post-body post-page__content__body" [innerHTML]="postContentSanitizer"></div>
  </div>
  <aside class="post-page__recents recents-posts" *ngIf="posts?.length > 0">
    <h3 class="recents-posts__title">Related Posts</h3>
    <ul class="recents-posts__list">
      <li *ngFor="let post of posts">
        <a [routerLink]="['/', PATHS[ROUTES.BLOG], post?.uri]" class="inner-link" [innerHTML]="post?.title"></a>
      </li>
      <br />
      <li><a [routerLink]="'/' + PATHS[ROUTES.BLOG]" class="inner-link">All posts</a></li>
    </ul>
  </aside>
  <ngx-json-ld *ngIf="post.schema" [json]="post.schema"></ngx-json-ld>
</article>
