import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import posts, { IPost } from "src/assets/posts";
import { ActivatedRoute } from "@angular/router";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ROUTES, PATHS } from "src/constants";
import { SeoService } from "src/app/services/seo/seo.service";

@Component({
  selector: "app-post",
  templateUrl: "./post.component.html",
  styleUrls: ["./post.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PostComponent implements OnInit {
  public ROUTES = ROUTES;
  public PATHS = PATHS;
  public posts: IPost[];
  public post: IPost;
  public postContentSanitizer: SafeHtml;
  constructor(private activatedRoute: ActivatedRoute, private seo: SeoService, private sanitizer: DomSanitizer) {}

  public ngOnInit(): void {
    this.activatedRoute.url.subscribe(segments => {
      this.post = null;
      this.posts = [];
      const uri = segments.pop();
      posts.forEach(p => {
        if (p.uri !== uri.toString()) {
          if (this.posts.length <= 4) this.posts.push(p);
        } else {
          this.post = p;
          this.postContentSanitizer = this.sanitizer.bypassSecurityTrustHtml(p.contentText);
        }
      });
      this.seo.setCanonicalURL();
      this.seo.updateMeta({
        title: this.post.title,
        description: this.post.subtitle,
        imageUrl: this.post.images[0].image,
      });
    });
  }
}
