<main id="main" class="blog-page">
  <h1 class="blog-page__title">Coara blog</h1>
  <div class="article-holder blog-page__content">
    <article class="post-article blog-page__content__post" *ngFor="let post of posts">
      <div class="img-holder">
        <a [routerLink]="post?.uri">
          <img
            [attr.loading]="'eager'"
            src="{{ (post?.images)[0]?.image }}"
            alt="{{ (post?.images)[0]?.alt }}"
            class="img-responsive lazy"
          />
          <div class="caption text-center text-lowercase">
            <span class="holder"><i class="arrow right-arrow"></i></span>
          </div>
        </a>
      </div>
      <div class="text">
        <h2><a [routerLink]="post.uri" [innerHTML]="post?.title"></a></h2>
        <p class="excerpt" [innerHTML]="post?.subtitle"><span class="post-grad"></span></p>
        <div class="meta">
          <!-- <em><a href="#">News</a> — <a href="#">Branding</a> — <a href="#">Project</a></em> -->
          <time [dateTime]="post.publishDate | date">{{ post.publishDate | date }} </time>
        </div>
      </div>
    </article>
  </div>
</main>
