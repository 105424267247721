import { Injectable, Inject } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class UtilsService {
  private defaultFormat: "jpg" | "webp";
  constructor(@Inject("isBrowser") private isBrowser: boolean) {
    if (this.isBrowser && ["Firefox", "Chrome"].filter(browser => navigator.userAgent.includes(browser)).length > 0) {
      this.defaultFormat = "webp";
    } else {
      this.defaultFormat = "jpg";
    }
  }

  public preloadImage(urls: string[], format: "jpg" | "webp" = this.defaultFormat): void {
    if (this.isBrowser) {
      (urls || []).forEach(url => {
        const img = new Image();
        img.src = `${url}.${format}`;
      });
    }
  }
}
