export const ROUTES = {
  HOME: "HOME",
  WHAT: "WHAT",
  WORKS: "WORKS",
  FOUR_HUNDRED_FOUR: "FOUR_HUNDRED_FOUR",
  BLOG: "BLOG",
  INCUBATOR: "INCUBATOR",
};

interface IPath {
  [route: string]: string;
}
export const PATHS: IPath = {
  HOME: "",
  WHAT: "what",
  WORKS: "works",
  FOUR_HUNDRED_FOUR: "404",
  BLOG: "blog",
  INCUBATOR: "incubator",
};
